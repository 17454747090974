import {timeFormat, numberFormat} from "@custom/index";
// tabs
export const tabOptions = [
    {
        label: "人员管理",
        name: "personnel"
    },
    {
        label: "事件查询",
        name: "event"
    },
];
//人员管理 列表
export const personnelTableColumn = [
    {
        prop: "asstesSn",
        label: "工号",
        width: 100,
    },
    {
        prop: "state",
        label: "用户类型",
        width: 150,
    },
    {
        prop: "asstesName",
        label: "姓名",
        width: 150,
    },
    {
        prop: "assetType",
        label: "层号",
        width: 150,
    },
    {
        prop: "model",
        label: "房间号",
        width: 150,
    },
    {
        prop: "price",
        label: "人脸数量",
        width: 150,
        render: (h, {row}) => {
            return h("span", numberFormat(row.price / 100));
        },
    },
    {
        prop: "communityName",
        label: "卡片数量",
        width: 150,
        showOverflowTooltip: true
    },
    {
        prop: "name",
        label: "开始时间",
        showOverflowTooltip: true
    },
    {
        prop: "11",
        label: "结束时间",
    },
];
//事件查询 列表
export const eventTableColumn = [
    {
        prop: "jobNum",
        label: "工号",
        width: 100,
    },
    {
        prop: "name",
        label: "姓名",
    },
    {
        prop: "cardNum",
        label: "卡号",
    },
    {
        prop: "eventName",
        label: "事件类型",
    },
    {
        prop: "createTime",
        label: "时间",
        showOverflowTooltip: true
    },
];