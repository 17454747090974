<template>
    <section class="entrance-guard">
        <div class="entrance-guard-header flex justify-between">
            <div>
                <el-badge class="item" v-for="(item,index) in tabOptions" :key="index" :value="item.value" hidden :max="99">
                    <div :class="['tab', { 'active-tab': tab === item.name }]" @click="tab = item.name">
                        {{ item.label }}
                    </div>
                </el-badge>
            </div>
        </div>
        <div class="container-content hidden-scrollbar">
            <component :is="tab" />
        </div>
    </section>
</template>

<script>
    import {tabOptions} from "./data";

    export default {
        name: "entrance-guard",
        data() {
            return {
                tab: "personnel",
                tabOptions
            };
        },
        components: {
            personnel:()=>import("./part/personnel"),
            event:()=>import("./part/event"),
            //supplier:()=>import("./part/supplier"),
            //inventory:()=>import("./part/inventory"),
            //warehouse:()=>import("./part/warehouse"),
        },
        methods: {
        },
        mounted() {

        }
    }
</script>
<style lang="scss" scoped>
    .entrance-guard {

        .entrance-guard-header {
            width: 75%;
            position: fixed;
            top: VH(40px);

            .tools {
                margin-left: VW(10px);

                .btn {
                    font-size: rem(12px);
                    cursor: pointer;
                    padding: VH(7px) VW(15px);
                    margin-left: VW(10px);

                    &.active-btn {
                        color: #fff;
                        border-radius: 4px;
                        background: linear-gradient(80deg, #A8D4FF 0%, #2386F6 100%);
                        box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
                    }
                }

                .el-date-editor {
                    margin: 0 VW(10px);
                }
            }

            .tab {
                cursor: pointer;
                padding: VH(7px) VW(15px);
                border-radius: 4px;
                box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);

                &.active-tab {
                    color: #fff;
                    //border-radius: 4px;
                    background: linear-gradient(80deg, #A8D4FF 0%, #2386F6 100%);
                    //box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
                }
            }
        }

        .item {
            margin: 0 15px;
        }
    }
</style>